import React, { useEffect, useRef } from 'react'
import "../styles/buttons.css";
import logo from "../assets/logo_block.png";

export default function Help(props) {
  const rewind = useRef()
  useEffect(() => {
    props.setMenu(false)
    props.setLookMenu(true)
  }, [])

  return (
    <div className="help_section">
      <div style={{ height: "100dvh", display: "grid", padding: "3rem" }}>
        <div
          style={{
            display: "grid",
            marginTop: "auto",
            marginBottom: "auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center" }} className="nostalgic_title">
            Be Kind, Rewind.
          </div>
          <img
            style={{ maxHeight: "50dvh" }}
            alt=""
            width={"100%"} src={logo}></img>
          <div
            style={{
              marginTop: "auto",
              justifyContent: "center",
              display: "grid",
              marginBottom: "100px"
            }}
          >
            <button onClick={() => rewind.current.scrollIntoView({ behavior: "smooth" })} className="classic_button">
              Rewind
            </button>
          </div>
        </div>
      </div>
      <div ref={rewind} style={{ padding: "1rem" }}></div>
      <div style={{ fontSize: "1.2rem" }} className="nostalgic_title">Welcome to Blockbuster world!</div>

      <div>

      </div>

      <div className="nostalgic_title paragraph">
        Hi everyone, I'm Gwardo420.
      </div>

      <div className="nostalgic_title paragraph">
        The developer behind the Blockbuster world website and the Block Telegram Bot. If you're a fan of classic movies, these projects were created to help you explore and rediscover your favorites.
      </div>

      <div className="nostalgic_title paragraph">
        I'm excited to share my passion for movies and technology with you. Let's delve into the world of Blockbuster!
        <hr style={{ width: "100%" }}></hr>
      </div>

      <div style={{ fontSize: "1.2rem" }} className="nostalgic_title">Membership Generator</div>

      <div className="nostalgic_title paragraph">
        Get a personalized membership badge with your name in minutes.
      </div>

      <div className="nostalgic_title paragraph">
        Our badge creator makes it simple to generate and download your badge.
      </div>

      <div style={{
        marginRight: "auto",
        marginLeft: "auto",
        maxWidth: "600px",
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
        padding: "1rem",
      }}>
        <img
          alt=""
          style={{
            borderRadius: "1rem",
            border: "2px var(--blockbuster-yellow-color) solid",
            width: "100%",
          }}
          src="https://i.imgur.com/nHKvU8a.gif"></img>
      </div>

      <div style={{ fontSize: "1.2rem" }} className="nostalgic_title">Block AI</div>

      <div className="nostalgic_title paragraph">
        Stuck for words? Our Block AI can help!
      </div>

      <div className="nostalgic_title paragraph">
        Just tell it what you're thinking about #blockbusteronsolana and it will generate a tweet for you in seconds.
      </div>

      <div style={{
        marginRight: "auto",
        marginLeft: "auto",
        maxWidth: "600px",
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
        padding: "1rem",
      }}>
        <img
          alt=""
          style={{
            borderRadius: "1rem",
            border: "2px var(--blockbuster-yellow-color) solid",
            width: "100%",
          }}
          src="https://i.imgur.com/8q4Ywxq.gif"></img>
      </div>
      <div style={{ padding: "1rem" }}></div>
    </div>
  )
}
