import "./styles/App.css";
import './styles/buttons.css';
import "./styles/interactions.css";

import { useState, lazy, Suspense } from "react";
import { Routes, Route, Link } from "react-router-dom";
import Price from "./utils/Price";
import Help from "./components/Help";
// import Blockbuster from "./components/Blockbuster";
// import Generator from "./components/Generator";

const Interior = lazy(() => import("./components/Interior"))
const Outdoor = lazy(() => import("./components/Outdoor"))
const Theater = lazy(() => import("./components/Theater"))
const MembershipCard = lazy(() => import("./components/Membership"))
const Blockbuster = lazy(() => import("./components/Blockbuster"))
const Generator = lazy(() => import("./components/Generator"))

function App() {
  const [light, setLight] = useState(false);
  const [explore, setExplore] = useState(false);
  const [lookMenu, setLookMenu] = useState(false);

  return (
    <>
      <Routes>
        <Route path="/" element={
          <Suspense fallback=
            {<div style={{ display: "grid", textAlign: "center", alignContent: "center", justifyContent: "center", marginTop: "auto", marginBottom: "auto", top: 0, bottom: 0, height: "100dvh", fontSize: "2rem", color: "yellow" }}>Loading Nostalgia...</div>}>
            <Outdoor setMenu={(x) => setExplore(x)} light={light} setLookMenu={(x) => setLookMenu(x)} />
          </Suspense>
        } />
        <Route path="/showroom" element={
          <Suspense fallback=
            {<div style={{ display: "grid", textAlign: "center", alignContent: "center", justifyContent: "center", marginTop: "auto", marginBottom: "auto", top: 0, bottom: 0, height: "100dvh", fontSize: "2rem", color: "yellow" }}>Loading Showroom...</div>}>
            <Interior setMenu={(x) => setExplore(x)} light={light} setLookMenu={(x) => setLookMenu(x)} />
          </Suspense>
        } />
        <Route path="/theater" element={
          <Suspense fallback=
            {<div style={{ display: "grid", textAlign: "center", alignContent: "center", justifyContent: "center", marginTop: "auto", marginBottom: "auto", top: 0, bottom: 0, height: "100dvh", fontSize: "2rem", color: "yellow" }}>Loading Theater...</div>}>
            <Theater setMenu={(x) => setExplore(x)} light={light} setLookMenu={(x) => setLookMenu(x)} />
          </Suspense>
        } />
        <Route path="/$BLOCK" element={
          <Suspense fallback={
            <div style={{ display: "grid", textAlign: "center", alignContent: "center", justifyContent: "center", marginTop: "auto", marginBottom: "auto", top: 0, bottom: 0, height: "100dvh", fontSize: "2rem", color: "yellow" }}>Loading Blockbuster...</div>
          }>
            <Blockbuster setMenu={(x) => setExplore(x)} setLookMenu={(x) => setLookMenu(x)} />
          </Suspense>
        } />
        <Route path="/membership" element={
          <Suspense fallback=
            {<div style={{ display: "grid", textAlign: "center", alignContent: "center", justifyContent: "center", marginTop: "auto", marginBottom: "auto", top: 0, bottom: 0, height: "100dvh", fontSize: "2rem", color: "yellow" }}>Loading Membership...</div>}>
            <MembershipCard setMenu={(x) => setExplore(x)} light={light} setLookMenu={(x) => setLookMenu(x)} />
          </Suspense>
        } />
        <Route path="/generator" element={
          <Suspense fallback=
            {<div style={{ display: "grid", textAlign: "center", alignContent: "center", justifyContent: "center", marginTop: "auto", marginBottom: "auto", top: 0, bottom: 0, height: "100dvh", fontSize: "2rem", color: "yellow" }}>Loading Membership...</div>}>
            <Generator setMenu={(x) => setExplore(x)} light={light} setLookMenu={(x) => setLookMenu(x)} />
          </Suspense>
        } />
        <Route path="/help" element={
          <Suspense fallback=
            {<div style={{ display: "grid", textAlign: "center", alignContent: "center", justifyContent: "center", marginTop: "auto", marginBottom: "auto", top: 0, bottom: 0, height: "100dvh", fontSize: "2rem", color: "yellow" }}>Loading Help...</div>}>
            <Help setMenu={(x) => setExplore(x)} light={light} setLookMenu={(x) => setLookMenu(x)} />
          </Suspense>
        } />
      </Routes>

      {explore === true && lookMenu === true && (
        <>
          <div style={{ height: "100%", background: "#313131a1", width: "100%", position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 10000 }}>
            <div style={{ marginBottom: "25px", zIndex: 10000, gap: ".25rem", display: "grid" }} className="front_door nostalgic_text">
              <Link style={{ textDecoration: "none", color: "white", background: "transparent", zIndex: 10000 }} to="/">Parking Lot</Link>
              <div></div>
              <Link style={{ textDecoration: "none", color: "white", background: "transparent", zIndex: 10000 }} to="/showroom">Showroom</Link>
              <div></div>
              <Link style={{ textDecoration: "none", color: "white", background: "transparent", zIndex: 10000 }} to="/theater">Theater</Link>
              <div></div>
              <Link style={{ textDecoration: "none", color: "white", background: "transparent", zIndex: 10000 }} to="/membership">Membership</Link>
              <div></div>
              <Link style={{ textDecoration: "none", color: "white", background: "transparent", zIndex: 10000 }} to="/generator">Tweet Generator</Link>
            </div>

            <div onClick={() => setExplore(!explore)} className="front_door nostalgic_text">
              Explore
            </div>
          </div>
        </>
      )}

      {explore === false && lookMenu === true && (
        <>
          <div onClick={() => setLight(!light)} style={{ marginBottom: "25px" }} className="front_door">
            {light === false ? <div className="nostalgic_text" style={{ background: "transparent" }}>Nightime</div> : <div className="nostalgic_text" style={{ background: "transparent" }}>Daytime</div>}
          </div>

          <div onClick={() => setExplore(true)} className="front_door nostalgic_text">
            Explore
          </div>

          <div onClick={() => window.open("https://www.twitter.com/devgwardonft")} className="creator nostalgic_text">
            Creator
          </div>

          <div className="front_door nostalgic_text" style={{ color: "white", top: 0, position: "absolute", display: "flex", alignItems: "center", justifyContent: "space-between", width: "90%", height: "25px" }}>
            <Price />
            <div>Test</div>
            <div>
              <Link style={{ textDecoration: "none", color: "white", background: "transparent", zIndex: 10000 }} to="/help">Help</Link>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default App;
