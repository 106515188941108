import React, { useState, useEffect, lazy, Suspense } from 'react';
import image from "../assets/logo_block.png";
import { getPrice } from "../server/getPrice.js";

export default function Price() {
  const [price, setPrice] = useState(0);

  async function fetchBlockPrice() {
    const price = await getPrice();
    setPrice(price.data.pair.priceUsd)
  }

  useEffect(() => {
    fetchBlockPrice()
  }, [])

  return (
    <div onClick={() => window.open("https://birdeye.so/token/ERKYpXQNHXNJxgWBt9X5CSs4w1SSLWmXMsm1zRCoiqPs?chain=solana")} style={{ background: "transparent" }}>
      {price > 0 ? (
        <div
          style={{ display: "flex", alignItems: "center", background: "transparent" }}
        ><img alt="" style={{ background: "transparent", marginRight: "10px" }} height={25} src={image}></img><div style={{ background: "transparent" }}>${price}</div></div>
      ) : (
        <div style={{ background: "transparent" }}>Fetching Price...</div>
      )}
    </div>
  )
}
